import { useCommonServices } from '@/services/CommonServicesProvider';
import { useQuery } from 'react-query';
import Loader, { isLoaderType, LoaderType } from '@/components/Loader';
import { ReactNode } from 'react';

interface Props<T> {
  queryKey: string | string[];
  fetch: () => Promise<T>;
  enabled?: boolean;
  builder: (data: T, refetch: () => void) => ReactNode;
  loader?: LoaderType | ReactNode;
  refetchOnWindowFocus?: boolean;
}

export default function AsyncComponent<T>({
  queryKey,
  fetch,
  builder,
  loader,
  refetchOnWindowFocus = false,
  enabled = true,
}: Props<T>) {
  const { logService } = useCommonServices();

  const { data, isLoading, refetch, error } = useQuery(queryKey, fetch, {
    suspense: false,
    onError: (error: Error) => {
      logService.error(`Error fetching data:${error}`);
    },
    refetchOnWindowFocus: refetchOnWindowFocus,
    enabled: enabled,
  });

  if (isLoading || !enabled) {
    const effectiveLoader = loader ?? 'default';

    return isLoaderType(effectiveLoader) ? (
      <Loader type={effectiveLoader} />
    ) : (
      <>{effectiveLoader}</>
    );
  }

  if (!data) {
    if (error) {
      throw error;
    }
    throw new Error('Data from query not defined');
  }

  return <>{builder(data, refetch)}</>;
}
