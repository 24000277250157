import axios, { AxiosResponse } from 'axios';

export class FileDownloadService {
  async downloadFile(
    entryType: string,
    entryId: string,
    fileId: string | null,
    onProgress: (percentage: number) => void
  ): Promise<AxiosResponse<Blob>> {
    const response = await axios.get(
      `/file_downloads/${entryId}?entry_type=${entryType}&file_id=${fileId}`,
      {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const total = progressEvent.total || 0;
          const current = progressEvent.loaded;

          if (total) {
            const percentage = Math.round((current / total) * 100);
            onProgress(percentage);
          }
        },
      }
    );

    return response;
  }
}
