import { Profile } from '@/features/iam/types';
import { FC, Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCableSubscription } from '@/services/CableProvider';
import {
  Link as ToasterLink,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
  Toaster,
  useId,
  useToastController,
  Link as FluentLink,
  ToastTrigger,
} from '@fluentui/react-components';
import { Notification } from '@/features/notifications/types';
import { CABLE_NOTIFICATIONS_CHANNEL_KEY } from '@/constants';

interface NotificationToastProps {
  notification: Notification;
}

const NotificationToast: FC<NotificationToastProps> = ({ notification }) => (
  <Toast>
    <ToastTitle
      action={
        <ToastTrigger>
          <ToasterLink>
            <span className="close-icon" />
          </ToasterLink>
        </ToastTrigger>
      }
    >
      New Notification
    </ToastTitle>
    <ToastBody>{notification.message}</ToastBody>
    <ToastFooter>
      <Link to={notification.path}>
        <FluentLink>View</FluentLink>
      </Link>
    </ToastFooter>
  </Toast>
);

interface NotificationButtonProps {
  profile: Profile;
}

const NotificationBell: FC<{ hasUnreadNotifications: boolean }> = ({
  hasUnreadNotifications,
}) => {
  const location = useLocation();
  const shouldReplace = location.pathname.includes('/notifications');

  return (
    <Link to="/notifications" replace={shouldReplace}>
      <div className="relative">
        {hasUnreadNotifications && (
          <div className="badge-error badge badge-xs absolute top-[-2px] right-[-5px] z-50 scale-75"></div>
        )}
        <span
          className="menu-notification-icon"
          title={
            hasUnreadNotifications
              ? 'New notifications'
              : 'No new notifications'
          }
        />
      </div>
    </Link>
  );
};

const NotificationButton: FC<NotificationButtonProps> = ({
  profile: { activeOrganizationId, hasUnreadNotifications },
}) => {
  const toasterId = useId('notification-toaster');
  const { dispatchToast } = useToastController(toasterId);
  const [unreadNotifications, setUnreadNotifications] = useState(
    hasUnreadNotifications
  );

  const onNewNotification = (notification: Notification) =>
    dispatchToast(<NotificationToast notification={notification} />, {
      intent: 'info',
      position: 'top-end',
      timeout: 3000,
    });

  useCableSubscription<Notification>(
    CABLE_NOTIFICATIONS_CHANNEL_KEY,
    { activeOrganizationId },
    {
      received: (notification) => {
        setUnreadNotifications(true);
        onNewNotification(notification);
      },
    }
  );

  return (
    <Fragment>
      <Toaster toasterId={toasterId} offset={{ vertical: 70 }} limit={6} />

      <NotificationBell hasUnreadNotifications={unreadNotifications} />
    </Fragment>
  );
};

export default NotificationButton;
