import { AxiosResponse } from 'axios';

export function extractFileName(response: AxiosResponse) {
  const contentDispositionHeader = response.headers['content-disposition'];
  if (contentDispositionHeader) {
    const filenameMatch = contentDispositionHeader.match(/filename="(.+)"/);
    if (filenameMatch) {
      return filenameMatch[1];
    }
  }

  return 'file';
}

function download(blob: Blob, fileName: string) {
  const href = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  link.click();

  window.URL.revokeObjectURL(href);
}

export function downloadTextFile(
  file: string,
  fileName: string,
  fileType: string
) {
  const blob = new Blob([file], { type: fileType });
  download(blob, fileName);
}

export function downloadBlobFile(response: AxiosResponse<Blob>) {
  const fileName = extractFileName(response);
  const file = response.data;

  download(file, fileName);
}
