import axios from 'axios';
import {
  FileStorageEntry,
  FileStorageEntryLhjPermissions,
  FileStorageResponse,
} from '../types';

export class FileStorageService {
  async getFileStorageEntry(
    entryId: string | null
  ): Promise<FileStorageResponse> {
    const response = await axios.get(`/i_nodes/${entryId}`);

    return response.data;
  }

  async createDirectory(
    parentFolderId: number | string | null,
    folderName: string,
    lhjPermissions: FileStorageEntryLhjPermissions[],
    tags: string[]
  ): Promise<FileStorageEntry> {
    const response = await axios.post(`/i_nodes?parent_id=${parentFolderId}`, {
      i_node: {
        name: folderName,
        parent_id: parentFolderId,
        lhjPermissions,
        tags,
      },
    });

    return response.data;
  }

  async createFile(
    parentFolderId: number | string | null,
    file: File
  ): Promise<FileStorageEntry> {
    const formData = new FormData();

    formData.append('i_node[file]', file);
    if (parentFolderId != null) {
      formData.append('i_node[parent_id]', parentFolderId.toString());
    }

    const response = await axios.post(
      `/i_nodes?parent_id=${parentFolderId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }

  async deleteEntry(entryId: number): Promise<void> {
    const response = await axios.delete(`/i_nodes/${entryId}`);

    return response.data;
  }

  async editEntry(
    entryId: number,
    newName: string,
    lhjPermissions: FileStorageEntryLhjPermissions[],
    tags?: string[]
  ): Promise<FileStorageEntry> {
    const response = await axios.put(`/i_nodes/${entryId}`, {
      i_node: { name: newName, lhjPermissions, tags },
    });

    return response.data;
  }

  async getExport(folderId: number | string | null): Promise<string> {
    const response = await axios.get(`/i_nodes/${folderId ?? -1}/export`);

    return response.data;
  }

  async addEntryComment(
    entryId: number,
    content: string
  ): Promise<FileStorageEntry> {
    const response = await axios.post(`/i_nodes/${entryId}/comments/`, {
      comment: { content },
    });

    return response.data;
  }
}
