import { ModalController } from '@/components/modals/useModalController';
import Modal from '@/components/modals/Modal';
import { FormEvent, useState } from 'react';
import {
  OrganizationRegion,
  OrganizationType,
  Role,
} from '@/features/iam/types';
import { useParams } from 'react-router-dom';
import { useCommonServices } from '@/services/CommonServicesProvider';
import { useIamService } from '@/features/iam/services/useIamService';
import TextInput from '@/components/inputs/TextInput';
import Checkbox from '@/components/inputs/Checkbox';
import SelectDropdown from '@/components/inputs/SelectDropdown';
import { isValidEmail, isValidUserPhoneNumber } from '@/utils/validation-utils';
import Button from '@/components/Button';
import AlertBox from '@/components/AlertBox';

interface Props {
  controller: ModalController;
  possibleRoles: Role[];
  availableRegions: OrganizationRegion[];
  refetchOrganizationsUsers: () => void;
  organizationType: OrganizationType;
  canSetAdmin: boolean;
  availableSeats: number;
  stateContactEmail?: string;
}

export default function AddUserModal({
  controller,
  possibleRoles,
  availableRegions,
  refetchOrganizationsUsers,
  organizationType,
  canSetAdmin,
  availableSeats,
  stateContactEmail,
}: Props) {
  const iamService = useIamService();
  const { toastService } = useCommonServices();

  const { organizationId } = useParams();

  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [isRegionalManager, setIsRegionalManager] = useState(false);

  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [assignedRegions, setAssignedRegions] = useState<
    OrganizationRegion[] | null
  >(null);

  const rolesDropdownElements = possibleRoles.map((role) => {
    return { label: role.name, value: role.id };
  });

  const regionsDropdownElements = availableRegions.map((region) => {
    return { label: region.name, value: region };
  });

  const noSeatsAvailable = availableSeats <= 0;
  const isGodOrg = organizationType === 'GOD';

  const isAssignedRegionValid =
    !isRegionalManager ||
    (assignedRegions != null && assignedRegions.length > 0);

  const validUserPhoneNumber = (phoneNumber?: string): boolean => {
    if (!phoneNumber) {
      return true;
    }

    return isValidUserPhoneNumber(phoneNumber);
  };

  const areRolesValid = selectedRoles.length > 0;

  const isValid =
    isValidEmail(email) &&
    isAssignedRegionValid &&
    areRolesValid &&
    validUserPhoneNumber(phoneNumber);

  function resetState() {
    setEmail('');
    setSelectedRoles([]);
    setPhoneNumber('');
    setWasSubmitted(false);
  }

  function handleRoleChange(roleIds: number[]) {
    const newRoles = roleIds
      .map((roleId) => possibleRoles.find((r) => r.id === roleId))
      .filter((r) => r != null) as Role[];

    setSelectedRoles(newRoles);
  }

  const handleIsAdminChange = (isAdmin?: boolean) => {
    if (isAdmin != null) {
      setIsAdmin(isAdmin);
    }
  };

  const handleAssignedRegionsChange = (
    assignedRegions: OrganizationRegion[] | null
  ) => {
    setAssignedRegions(assignedRegions);
  };

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setWasSubmitted(true);

    if (isValid) {
      toastService.processing(
        () =>
          iamService
            .createOrganizationUser(
              organizationId as string,
              email,
              phoneNumber,
              selectedRoles,
              isAdmin,
              assignedRegions
            )
            .then((result) => {
              refetchOrganizationsUsers();
              resetState();
              controller.close();

              return result;
            }),
        (error) => error.response?.status === 422
      );
    } else {
      toastService.error('Please fill required fields');
    }
  }

  return (
    <Modal controller={controller} header="Add user">
      {!isGodOrg && noSeatsAvailable && (
        <div className="mt-2">
          <AlertBox>
            <span className="inline-block">
              Your organization does not have any remaining seats, please check
              with your{' '}
              {stateContactEmail ? (
                <a
                  className="underline"
                  href={`mailto:${stateContactEmail}?subject=Seat%20allocation`}
                >
                  state administrator
                </a>
              ) : (
                'state administrator'
              )}
            </span>
          </AlertBox>
        </div>
      )}
      <form onSubmit={onSubmit} className="min-w-[28rem]">
        <div className="form-group">
          <TextInput
            headerName="Email"
            value={email}
            type="email"
            placeholder="Email"
            isValid={!wasSubmitted || isValidEmail(email)}
            onChange={(email) => {
              setEmail(email.target.value);
            }}
          />

          <TextInput
            className="mb-3"
            placeholder="Phone number"
            onChange={(phoneNumber) => {
              setPhoneNumber(phoneNumber.target.value);
            }}
            value={phoneNumber}
            name="Invalid phone number"
            headerName="Phone number (optional)"
            isValid={!wasSubmitted || validUserPhoneNumber(phoneNumber)}
          />
        </div>

        <SelectDropdown
          name="Select roles"
          headerName="Roles"
          elements={rolesDropdownElements}
          valid={!wasSubmitted || areRolesValid}
          isMulti
          defaultSelectedValues={selectedRoles.map((r) => r.id)}
          onSelect={(elements) => {
            if (elements) {
              handleRoleChange(elements);
            }
          }}
        />

        {canSetAdmin && (
          <div className="mt-3 flex items-center">
            <p className="mr-3 text-base-sm font-semibold text-grey-black">
              Is admin:
            </p>
            <Checkbox
              isChecked={isAdmin}
              id={`is admin checkbox of user creation to ${organizationId}s`}
              onAnswer={(isChecked) => handleIsAdminChange(isChecked)}
            />
          </div>
        )}

        {organizationType === 'STATE' && (
          <>
            <div className="mt-3 flex items-center">
              <p className="mr-3 text-base-sm font-semibold text-grey-black">
                Is project officer:
              </p>
              <Checkbox
                isChecked={isRegionalManager}
                id={`is regional manager checkbox of adding new user to ${organizationId}`}
                onAnswer={(isChecked) => {
                  if (isChecked != null) {
                    setIsRegionalManager(isChecked);

                    if (!isChecked) {
                      handleAssignedRegionsChange(null);
                    }
                  }
                }}
              />
            </div>
            {isRegionalManager && (
              <>
                <SelectDropdown
                  isMulti
                  elements={regionsDropdownElements}
                  headerName="Assigned regions"
                  name="Select regions"
                  compare={(r1, r2) => r1.id === r2.id}
                  defaultSelectedValues={assignedRegions ?? undefined}
                  onSelect={(assignedRegions) =>
                    handleAssignedRegionsChange(assignedRegions)
                  }
                  valid={!wasSubmitted || isAssignedRegionValid}
                />
              </>
            )}
          </>
        )}

        <div className="mt-6 flex flex-row justify-end">
          <Button disabled={!isGodOrg && noSeatsAvailable} submit>
            Send invite
          </Button>
        </div>
      </form>
    </Modal>
  );
}
