import {
  AwardSummaryState,
  AwardType,
  FundingType,
} from '@/features/awards/types';
import { daysBetweenDates, toDateWithoutTime } from '@/utils/date-utils';
import {
  Organization,
  OrganizationPopulationSize,
  OrganizationType,
  OrganizationUserStatus,
  PermissionType,
  PlanType,
} from '@/features/iam/types';
import { DAYS_REMAINING } from '@/constants';
import {
  Container,
  ContainerType,
  Template,
  TemplateState,
  isOrganizerContainer,
} from '@/features/templates/types';
import { ContainerPair } from '@/features/planned-activities/types';
import {
  Document,
  DocumentState,
  IndirectCostBasis,
} from '@/features/documents/types';
import {
  Invoice,
  InvoiceItemType,
  InvoiceState,
} from '@/features/invoices/types';
import { ReportType } from '@/features/reports/types';
import { isValidName } from './validation-utils';
import { SummaryType } from '@/types/tree-types';
import { ExpenditureReportItem } from '@/features/expenditure-report/types';
import {
  PositionFilledWith,
  PositionFilledTiming,
} from '@/features/budget-categories/types';
import { FormInput } from '@/features/form-inputs/types';
import { NotificationType } from '@/features/notifications/types';
import { AwardDefinition } from '@/features/award-definitions/types';

export type PossibleState =
  | AwardSummaryState
  | TemplateState
  | DocumentState
  | InvoiceState;

export function displayOrganizationPopulationSize(
  populationSize: OrganizationPopulationSize
) {
  switch (populationSize) {
    case 'less_than_200k':
      return 'Less than 200 000';
    case 'between_200k_and_700k':
      return 'Between 200 000 and 699 999';
    case 'more_than_700k':
      return 'More than 700 000';
  }
}

export const displayFundingType = (type: FundingType): string => {
  switch (type) {
    case 'PLANNED':
      return 'Base';
    case 'SUPPLEMENTAL':
      return 'Supplemental';
    case 'CRI':
      return 'CRI';
    case 'LAB':
      return 'Lab';
    default:
      return '';
  }
};

export const displayAwardType = (type: AwardType): string => {
  return type.split('_').join(' ');
};

export const displayReportType = (type: ReportType): string => {
  switch (type) {
    case 'WORK_PLAN':
      return 'Work Plan';
    case 'SPEND_PLAN':
      return 'Spend Plan';
    case 'CPG':
      return 'Planning Guide';
    default:
      return type;
  }
};

export const displayFiscalYear = (fiscalYear: string): string =>
  `${fiscalYear}`;

export const displayBudgetPeriod = (budgetPeriod: number): string =>
  `BP${budgetPeriod}`;

export function displayContainerType(
  containerType: ContainerType,
  {
    displayDefaultContainerType = true,
  }: { displayDefaultContainerType?: boolean } = {}
) {
  switch (containerType) {
    case 'CAPABILITY':
      return 'Capability';
    case 'DOMAIN':
      return 'Domain';
    case 'FUNCTION':
      return 'Function';
    case 'OBJECTIVE':
      return 'Objective';
    case 'OBJECTIVE_ORGANIZER':
      return 'Objectives';
    case 'ACTIVITY':
      return 'Activity';
    case 'REQUIREMENT':
      return 'Requirement';
    case 'REQUIREMENT_ORGANIZER':
      return 'Minimum Requirements';
    case 'CPG_DEFINITION':
      return 'Planning Guide Definition';
    case 'WP_DEFINITION':
      return 'Work Plan Definition';
    case 'SP_DEFINITION':
      return 'Spend Plan Definition';
    case 'CPG_GUIDANCE':
      return 'Planning Guide Instruction';
    case 'WP_GUIDANCE':
      return 'Work Plan Instruction';
    case 'SP_GUIDANCE':
      return 'Spend Plan Instruction';
    case 'STRATEGY':
      return 'Strategy';
    case 'OUTCOME':
      return 'Outcome';
    case 'ACTIVITY_ORGANIZER':
      return 'Objective';
    case 'GOAL':
      return 'Goal';
    case 'PLANNED_ACTIVITY':
      return 'Planned activity';
    case 'DEFAULT_TYPE':
      if (!displayDefaultContainerType) {
        return '';
      }
      return 'Default';
  }
}

export const displayContainerLabel = (container: Container): string => {
  if (isValidName(container.label)) {
    // The isValid name already checks for the null
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return container.label!;
  }

  return displayContainerType(container.type);
};

export function displayContainerTypeWithPotentialOrder(
  container: Container
): string {
  const displayOrder =
    !isOrganizerContainer(container) && !isValidName(container.label);

  return `${displayContainerLabel(container)}${displayOrder ? ` ${container.order}` : ''}`;
}

export const displayContainerDescriptionName = (
  container: Container
): string => {
  if (isValidName(container.descriptionLabel)) {
    return container.descriptionLabel ?? '';
  }

  switch (container.type) {
    case 'OBJECTIVE':
    case 'FUNCTION':
    case 'ACTIVITY':
    case 'OUTCOME':
      return `${displayContainerLabel(container)} Description`;
    case 'CAPABILITY':
    case 'STRATEGY':
    case 'DOMAIN':
      return `${displayContainerLabel(container)} Definition`;
    case 'REQUIREMENT':
    case 'REQUIREMENT_ORGANIZER':
    case 'CPG_DEFINITION':
    case 'WP_DEFINITION':
    case 'SP_DEFINITION':
    case 'CPG_GUIDANCE':
    case 'WP_GUIDANCE':
    case 'SP_GUIDANCE':
    case 'OBJECTIVE_ORGANIZER':
    case 'ACTIVITY_ORGANIZER':
    case 'GOAL':
    case 'PLANNED_ACTIVITY':
    case 'DEFAULT_TYPE':
      return 'Description';
  }
};

export const shouldDisplayOverdue = (
  isOverdue: boolean,
  status: PossibleState
) =>
  isOverdue && new Array<PossibleState>('NEW', 'IN_PROGRESS').includes(status);

export const iconByType = (type: ContainerType): string => {
  switch (type) {
    case 'CAPABILITY':
    case 'STRATEGY':
      return 'cpg-capability-icon';
    case 'FUNCTION':
      return 'cpg-function-icon';
    default:
      return '';
  }
};

export const getStateDisplayName = (state: PossibleState): string => {
  switch (state) {
    case 'NEW':
      return 'New';
    case 'IN_PROGRESS':
      return 'In Progress';
    case 'PUBLISHED':
      return 'Published';
    case 'ARCHIVED':
      return 'Archived';
    case 'IN_REVIEW':
      return 'In Review';
    case 'IN_LOCAL_REVIEW':
      return 'In Local Review';
    case 'IN_REGIONAL_REVIEW':
      return 'In Regional Review';
    case 'IN_STATE_REVIEW':
      return 'In State Review';
    case 'APPROVED':
      return 'Approved';
    case 'NEEDS_REVIEW':
      return 'Needs Review';
    case 'NEEDS_LOCAL_REVIEW':
      return 'Needs Local Review';
    case 'NEEDS_REGIONAL_REVIEW':
      return 'Needs Regional Review';
    case 'NEEDS_STATE_REVIEW':
      return 'Needs State Review';
    case 'RETURNED':
      return 'Returned';
    case 'CANCELED':
      return 'Canceled';
  }
};

export const getBackgroundColor = (state: PossibleState): string => {
  switch (state) {
    case 'APPROVED':
      return '#E2FFE1';
    case 'NEW':
      return '#EEF9FF';
    case 'ARCHIVED':
      return '#F9F9F9';
    case 'IN_PROGRESS':
      return '#FDFBDB';
    case 'IN_REVIEW':
    case 'IN_LOCAL_REVIEW':
    case 'IN_REGIONAL_REVIEW':
    case 'IN_STATE_REVIEW':
      return '#FDFBDB';
    case 'NEEDS_REVIEW':
    case 'NEEDS_LOCAL_REVIEW':
    case 'NEEDS_REGIONAL_REVIEW':
    case 'NEEDS_STATE_REVIEW':
      return '#FFF4DE';
    case 'PUBLISHED':
      return '#E2FFE1';
    case 'RETURNED':
    case 'CANCELED':
      return '#FFE1E1';
  }
};

export const getForegroundColor = (state: PossibleState): string => {
  switch (state) {
    case 'APPROVED':
      return '#086906';
    case 'NEW':
      return '#141415';
    case 'ARCHIVED':
      return '#141415';
    case 'IN_PROGRESS':
      return '#141415';
    case 'IN_REVIEW':
    case 'IN_LOCAL_REVIEW':
    case 'IN_REGIONAL_REVIEW':
    case 'IN_STATE_REVIEW':
      return '#141415';
    case 'NEEDS_REVIEW':
    case 'NEEDS_LOCAL_REVIEW':
    case 'NEEDS_REGIONAL_REVIEW':
    case 'NEEDS_STATE_REVIEW':
      return '#B57A08';
    case 'PUBLISHED':
      return '#086906';
    case 'RETURNED':
    case 'CANCELED':
      return '#D30B0B';
  }
};

export const displayDate = (date: Date): string =>
  date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

export const displayDateTime = (date: Date): string =>
  date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

export const displayDaysRemaining = (
  row: Template | Document
): { days: number | null; isPublished: boolean } => {
  const isPublished = row.state === 'PUBLISHED';

  const days =
    row.overdueDate == null
      ? null
      : isPublished
        ? 0
        : Math.max(
            daysBetweenDates(toDateWithoutTime(row.overdueDate), new Date()),
            0
          );
  return { days, isPublished };
};

export const displayHighlightsRemainingDays = ({ value }) => {
  const { days, isPublished }: { days: number | null; isPublished: boolean } =
    value;

  const daysText = days == null ? '-' : `${days} day${days !== 1 ? 's' : ''}`;
  const className =
    days != null && days < DAYS_REMAINING && !isPublished ? 'text-orange' : '';

  return <span className={className}>{daysText}</span>;
};

export function displayUserStatusName(status: OrganizationUserStatus) {
  return new Map<OrganizationUserStatus, string>([
    ['active', 'Active'],
    ['pending', 'Pending'],
    ['inactive', 'Inactive'],
  ]).get(status) as string;
}

export function displayPermission(permission: PermissionType) {
  return new Map<PermissionType, string>([
    ['EDIT', 'Edit'],
    ['APPROVE', 'Approve'],
  ]).get(permission) as string;
}

export function displayPlanType(planType: PlanType) {
  return new Map<PlanType, string>([
    ['CPG', 'Planning guide'],
    ['WORK_PLAN', 'Work plan'],
    ['SPEND_PLAN', 'Spend plan'],
  ]).get(planType) as string;
}

export function displayMoney(value: number | string) {
  let rowNumber = value;

  if (typeof value === 'string') {
    const parsed = Number.parseFloat(value);
    rowNumber = Number.isFinite(parsed) ? parsed : value;
  }

  return rowNumber.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
}

export function displayPercentage(value: string | number) {
  return `${value}%`;
}

export const displayOrganizationType = (type: OrganizationType): string => {
  switch (type) {
    case 'GOD':
      return 'God';
    case 'STATE':
      return 'State';
    case 'LOCAL':
      return 'Local';
  }
};

export const displayContainerPair = (containerPair: ContainerPair): string => {
  const parent = containerPair.parent;
  const child = containerPair.child;

  const parentName = parent
    ? displayContainerTypeWithPotentialOrder(parent)
    : null;
  const childName = displayContainerTypeWithPotentialOrder(child);

  return `${parentName ? `${parentName}, ` : ''}${childName}`;
};

export const displayOrganizationName = (organization: Organization): string => {
  const isDemo = organization.isDemo ?? false;

  return `${organization.name}${isDemo ? ' (DEMO)' : ''}`;
};

export const displayUsedSeats = (organization: Organization) => {
  if (organization.type === 'LOCAL' || organization.type === 'STATE') {
    return `${organization.usedSeats} / ${organization.allocatedSeats} seats used`;
  }
};

export function displayPlannedActivityWithOrder(order: number, label?: string) {
  if (isValidName(label)) {
    return label ?? '';
  }

  return `Planned activity ${order}`;
}

export function displayFormInputWithOrder(formInput: FormInput, order: number) {
  if (isValidName(formInput.sidebarLabel)) {
    return formInput.sidebarLabel ?? '';
  }

  return `${formInput.name} ${order}`;
}

export function displayIndirectCostBasis(indirectCostBasis: IndirectCostBasis) {
  switch (indirectCostBasis) {
    case 'direct':
      return 'Indirect cost based on direct';
    case 'personnel':
      return 'Indirect cost based on personnel';
    case 'no_basis':
      return 'No indirect cost basis';
  }
}

export function displayReportUpdatedAt(updatedAt: number) {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    // Converting seconds received by BE to miliseconds used in the FE
  }).format(updatedAt * 1000);
}

export function displayFophObjectiveWithOrder(order: number) {
  return `Objective ${order}`;
}

export function displayAwardDefinition(awardDefinition: AwardDefinition) {
  return `From ${awardDefinition.validFrom} to ${awardDefinition.validTo}`;
}

export function displayMonth(month: number, year: number) {
  const date = new Date(year, month - 1);
  const monthName = date.toLocaleString('default', { month: 'short' });

  return `${monthName} ${year}`;
}

export function displaySummaryType(summaryType: SummaryType) {
  switch (summaryType) {
    case 'BUDGET':
      return 'Summary';
    case 'INVOICE':
      return 'Invoices';
    case 'EXPENDITURE_REPORT':
      return 'Expenditure Report';
  }
}

export function displayPositionFilledWith(
  positionFilledWith: PositionFilledWith
) {
  switch (positionFilledWith) {
    case 'new_hire':
      return 'New Hire';
    case 'current_employee':
      return 'Current Employee';
  }
}

export function displayPositionFilledTiming(
  positionFilledTiming: PositionFilledTiming
) {
  switch (positionFilledTiming) {
    case 'during_year_one':
      return 'During year 1';
    case 'after_year_one':
      return 'After year 1';
  }
}

export function displayPersonnelReportTemplate(template: Template) {
  return `${displayAwardType(template.awardType)} - ${template.name}`;
}

export function getNegativeValueClass(value: string) {
  const parsed = Number.parseFloat(value);

  return parsed < 0 ? 'text-red' : '';
}

export function displayExpenditureReportItem(item: ExpenditureReportItem) {
  const endMonth =
    item.endMonth != item.month || item.endYear != item.endYear
      ? ` - ${displayMonth(item.endMonth, item.endYear)}`
      : '';

  return `${displayMonth(item.month, item.year)}${endMonth}`;
}

export function displayExpenditureReportItemIndirectCostOverrideWarning(
  item: ExpenditureReportItem
) {
  return item.month == item.endMonth && item.year == item.endYear
    ? 'The default indirect cost rate has been adjusted.'
    : 'The default indirect cost rate has been adjusted for at least one month within the specified time period.';
}

export function displayTotalCostTitle(summaryType: SummaryType) {
  switch (summaryType) {
    case 'BUDGET':
      return 'Total budgeted';
    case 'INVOICE':
      return 'Total invoices';
    case 'EXPENDITURE_REPORT':
      return 'Total expenditures';
  }
}

export function displaySummaryTitle(summaryType: SummaryType) {
  switch (summaryType) {
    case 'BUDGET':
      return 'Budget Summary';
    case 'INVOICE':
      return 'Invoices Summary';
    case 'EXPENDITURE_REPORT':
      return 'Expenditures Summary';
  }
}

export function displaySummaryNominalValueTitle(summaryType: SummaryType) {
  switch (summaryType) {
    case 'BUDGET':
      return 'Budgeted value';
    case 'INVOICE':
      return 'Invoiced value';
    case 'EXPENDITURE_REPORT':
      return 'Expensed value';
  }
}

export function displayNotificationType(notificationType: NotificationType) {
  switch (notificationType) {
    case 'CPG_TEMPLATE_STATE_CHANGE_MAILER':
      return 'Planning Guide template';
    case 'CPG_DOCUMENT_STATE_CHANGE_MAILER':
      return 'Planning Guide document';
    case 'WORK_PLAN_TEMPLATE_STATE_CHANGE_MAILER':
      return 'Work Plan template';
    case 'WORK_PLAN_DOCUMENT_STATE_CHANGE_MAILER':
      return 'Work Plan document';
    case 'SPEND_PLAN_TEMPLATE_STATE_CHANGE_MAILER':
      return 'Spend Plan template';
    case 'SPEND_PLAN_DOCUMENT_STATE_CHANGE_MAILER':
      return 'Spend Plan document';
    case 'WORK_PLAN_NEEDS_ASSISTANCE_MAILER':
      return 'Work Plan needs assistance';
    case 'INVOICE_STATE_CHANGE_MAILER':
      return 'Invoice';
  }
}

export function displayNotificationHelpText(
  notificationType: NotificationType
) {
  switch (notificationType) {
    case 'CPG_TEMPLATE_STATE_CHANGE_MAILER':
      return 'Enable email notifications for template status changes';
    case 'CPG_DOCUMENT_STATE_CHANGE_MAILER':
      return 'Enable email notifications for document status changes';
    case 'WORK_PLAN_TEMPLATE_STATE_CHANGE_MAILER':
      return 'Enable email notifications for template status changes';
    case 'WORK_PLAN_DOCUMENT_STATE_CHANGE_MAILER':
      return 'Enable email notifications for document status changes';
    case 'SPEND_PLAN_TEMPLATE_STATE_CHANGE_MAILER':
      return 'Enable email notifications for template status changes';
    case 'SPEND_PLAN_DOCUMENT_STATE_CHANGE_MAILER':
      return 'Enable email notifications for document status changes';
    case 'WORK_PLAN_NEEDS_ASSISTANCE_MAILER':
      return 'Enable email notifications when organizations need assistance with their work plan';
    case 'INVOICE_STATE_CHANGE_MAILER':
      return 'Enable email notifications for invoice status changes';
  }
}

export function displayInvoiceItemType(invoiceItem: InvoiceItemType) {
  switch (invoiceItem) {
    case 'InvoiceItem::ExpenseItem':
      return 'Expense item';
    case 'InvoiceItem::AdvancedPayment':
      return 'Advanced payment';
  }
}

export function displayInvoiceSubmissionDate(invoice: Invoice) {
  return invoice.submittedAt
    ? displayDate(toDateWithoutTime(invoice.submittedAt))
    : 'Not submitted';
}

export function displayInvoiceApprovalDate(invoice: Invoice) {
  return invoice.approvedAt
    ? displayDate(toDateWithoutTime(invoice.approvedAt))
    : 'Not approved';
}
