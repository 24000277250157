import axios from 'axios';
import {
  Invoice,
  InvoiceDetails,
  InvoiceEditPayload,
  InvoiceFormData,
  InvoiceItemFormData,
  InvoicesResponse,
} from '@/features/invoices/types';
import { Summary } from '@/features/documents/types';
import { extractFileName } from '@/features/file-download/utils/file-download-utils';

export class InvoiceService {
  async getInvoices(spendPlanId: string): Promise<InvoicesResponse> {
    const response = await axios.get(`/spend_plans/${spendPlanId}/invoices`);

    return response.data;
  }

  async getInvoice(
    spendPlanId: string,
    invoiceId: string
  ): Promise<InvoiceDetails> {
    const response = await axios.get(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}`
    );

    return response.data;
  }

  async addInvoice(
    spendPlanId: string,
    invoice: InvoiceFormData
  ): Promise<Invoice> {
    const response = await axios.post(`/spend_plans/${spendPlanId}/invoices/`, {
      invoice,
    });

    return response.data;
  }

  async editInvoice(
    spendPlanId: string,
    invoiceId: string,
    invoice: InvoiceEditPayload
  ): Promise<InvoiceDetails> {
    const response = await axios.put(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}`,
      {
        invoice,
      }
    );

    return response.data;
  }

  async addInvoiceItem(
    spendPlanId: string,
    invoiceId: string,
    invoiceItem: InvoiceItemFormData
  ): Promise<InvoiceDetails> {
    const response = await axios.post(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/invoice_items`,
      { invoice_item: invoiceItem }
    );

    return response.data;
  }

  async editInvoiceItem(
    spendPlanId: string,
    invoiceId: string,
    invoiceItemId: string,
    invoiceItem: InvoiceItemFormData
  ): Promise<InvoiceDetails> {
    const { budgetItem, budgetCategory, ...editPayload } = invoiceItem;

    // eslint-disable-next-line @typescript-eslint/dot-notation
    delete editPayload['id'];

    const response = await axios.put(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/invoice_items/${invoiceItemId}`,
      {
        invoice_item: {
          ...editPayload,
          budgetItemId: budgetItem === null ? null : budgetItem?.id,
          budgetCategoryId: budgetCategory === null ? null : budgetCategory?.id,
        },
      }
    );

    return response.data;
  }

  async uploadInvoiceFiles(
    spendPlanId: string,
    invoiceId: string,
    files: File[]
  ): Promise<InvoiceDetails> {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files[]', file);
    });

    const response = await axios.post(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }

  async removeInvoiceFile(
    spendPlanId: string,
    invoiceId: string,
    fileId: string
  ): Promise<InvoiceDetails> {
    const response = await axios.delete(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/uploads/${fileId}`
    );

    return response.data;
  }

  async uploadInvoiceItemFiles(
    spendPlanId: string,
    invoiceId: string,
    invoiceItemId: string,
    files: File[]
  ): Promise<InvoiceDetails> {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files[]', file);
    });

    const response = await axios.post(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/invoice_items/${invoiceItemId}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }

  async removeInvoiceItemFile(
    spendPlanId: string,
    invoiceId: string,
    invoiceItemId: string,
    fileId: string
  ): Promise<InvoiceDetails> {
    const response = await axios.delete(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/invoice_items/${invoiceItemId}/uploads/${fileId}`
    );

    return response.data;
  }

  async removeInvoiceItem(
    spendPlanId: string,
    invoiceId: string,
    invoiceItemId: string
  ): Promise<InvoiceDetails> {
    const response = await axios.delete(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/invoice_items/${invoiceItemId}`
    );

    return response.data;
  }

  async submit(
    spendPlanId: string,
    invoiceId: string
  ): Promise<InvoiceDetails> {
    const response = await axios.post(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/submit`
    );

    return response.data;
  }

  async startReview(
    spendPlanId: string,
    invoiceId: string
  ): Promise<InvoiceDetails> {
    const response = await axios.post(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/review`
    );

    return response.data;
  }

  async return(
    spendPlanId: string,
    invoiceId: string
  ): Promise<InvoiceDetails> {
    const response = await axios.post(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/return`
    );

    return response.data;
  }

  async approve(
    spendPlanId: string,
    invoiceId: string
  ): Promise<InvoiceDetails> {
    const response = await axios.post(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/approve`
    );

    return response.data;
  }

  async draft(spendPlanId: string, invoiceId: string): Promise<InvoiceDetails> {
    const response = await axios.post(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/draft`
    );

    return response.data;
  }

  async cancel(
    spendPlanId: string,
    invoiceId: string
  ): Promise<InvoiceDetails> {
    const response = await axios.post(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/cancel`
    );

    return response.data;
  }

  async addReviewComment(
    spendPlanId: string,
    invoiceId: string,
    content = ''
  ): Promise<InvoiceDetails> {
    const response = await axios.post(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/comments/`,
      { comment: { content } }
    );

    return response.data;
  }

  async getInvoiceSummary(spendPlanId: string): Promise<Summary> {
    const response = await axios.get(
      `/spend_plans/${spendPlanId}/invoice_summary`
    );

    return response.data;
  }

  async downloadInvoicePdf(
    spendPlanId: string,
    invoiceId: string
  ): Promise<{ fileName: string; data: string }> {
    const response = await axios.get(
      `/spend_plans/${spendPlanId}/invoices/${invoiceId}/download`,
      { responseType: 'arraybuffer' }
    );

    const fileName = extractFileName(response);

    return { fileName: fileName, data: response.data };
  }
}
